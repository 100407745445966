import { GetStaticProps } from 'next';
import { NextSeo, NextSeoProps } from 'next-seo';
import { useEffect } from 'react';

import { ContentStackService, Monetate, seoMap, useAnalytics } from 'services';

import { PageBuilder, pageBuilderMap, PageBuilderMap, useDispensary } from 'ui';

type Props = {
  components: PageBuilderMap[];
  seo: NextSeoProps;
};

export default function HomePage({ components, seo }: Props) {
  const analytics = useAnalytics();
  const { selectedDispensary } = useDispensary();
  useEffect(() => {
    analytics.pushPageView({
      event: 'page_view',
      m: 'v1',
      ga: 4,
      page_type: 'home'
    });
    Monetate.setPageType('home');
  }, []);

  return (
    <>
      <NextSeo {...seo} />
      <div>
        {components?.length
          ? components.map((component, i) =>
              PageBuilder(component, i, selectedDispensary?.location.stateSlug)
            )
          : null}
      </div>
    </>
  );
}

export const getStaticProps: GetStaticProps<Props> = async (ctx) => {
  const isPreview = !!ctx.preview;
  const contentstack = new ContentStackService();
  const res = await contentstack.getLandingPageByUrl('/', isPreview);

  if (res.success && res.data?.items?.length) {
    const mappedComponents = [];
    for (const i of res.data.items[0].page_components) {
      mappedComponents.push(await pageBuilderMap(i, process.env.BRAND));
    }

    return {
      props: {
        components: mappedComponents,
        seo: seoMap({
          ...res.data.items[0].seo,
          url: `${process.env.WEBSITE_URL}/${res.data.items[0].url}`
        })
      },
      revalidate: +process.env.NEXT_REVALIDATE_TIME || 300
    };
  }

  return {
    notFound: true
  };
};
